export default {
  tableColumn1: 'Merchant ID',
  merchantCode: 'Merchant Code',
  tableColumn3: 'Merchant Name',
  tableColumn4: 'Short Name',
  tableColumn5: 'Logo',
  tableColumn6: 'Create time',
  tableColumn7: 'Owner',
  tableColumn8: 'Weight',
  tableColumn9: 'Inner Merchant',
  mergerAccount: 'Merger Account',
  resetPassword: {
    emailTip:
      'We will send the initial account password to this email address. Please fill in the correct email address carefully.',
    emailTipSkip: "If the email not filled, the password will be sent to merchant's email.",
    sendPws2Merchant: 'We will send the validation mail to ',
    merchantEmail: "merchant's email",
    sendPws2Email: 'We will send the initial account password to this email '
  }
}
