/**
 * @desc define api
 */

import http from '@/utils/http'

// login module
export const login = (data) => http.post({ url: '/admin/auth/lark/scan-code/login', data }, false)
export const authLogin = (data) => http.post({ url: '/admin/auth/login', data }, false)
export const getUserInfo = () => http.post({ url: '/admin/user/current-user/info' })
export const updateUserTimezone = (data) => http.post({ url: '/admin/user/timezone/modify', data })

// header
export const changePsw = (data) => http.post({ url: '/admin/user/password/change', data })

// access module
export const getUserList = (data) => http.post({ url: '/admin/user/page', data })
export const getUser = (userId) => http.post({ url: `/admin/user/detail/${userId}` })
export const addUser = (data) => http.post({ url: '/admin/user/add', data })
export const modifyUser = (data) => http.post({ url: '/admin/user/modify', data })
export const changeUserState = (data) => http.post({ url: '/admin/user/state/modify', data })
export const deleteUser = (userId) => http.post({ url: `/admin/user/remove/${userId}` })
export const getRoleList = (data) => http.post({ url: '/admin/role/page', data })
export const getAllRole = (data) => http.post({ url: '/admin/role/assignment/list', data })
export const getRole = (roleId) => http.post({ url: `/admin/role/detail/${roleId}` })
export const addRole = (data) => http.post({ url: '/admin/role/add', data })
export const modifyRole = (data) => http.post({ url: '/admin/role/modify', data })
export const changeRoleState = (data) => http.post({ url: '/admin/role/state/modify', data })
export const deleteRole = (roleId) => http.post({ url: `/admin/role/remove/${roleId}` })
export const resetPassword = (roleId) =>
  http.post({ url: `/admin/user/member-password/reset/${roleId}` })
export const getSystemAccount = (data) =>
  http.post({ url: '/admin/dashboard/inner-user/page', data })
export const sendPassword2Email = (data) =>
  http.post({ url: '/admin/dashboard/inner-user-password/get', data })

// case module
export const caseList = (data) => http.post({ url: '/admin/work-order/page', data })
export const caseStatistics = (data) =>
  http.post({ url: '/admin/work-order/status-statistics/query', data })
export const uploadCaseFiles = (data) => http.post({ url: '/admin/work-order/file/upload', data })
export const downloadCaseFiles = downloadFiles('/admin/work-order/file/download')
export const CaseInfo = (data) => http.post({ url: '/admin/work-order/detail', data })
export const updateCase = (data) => http.post({ url: '/admin/work-order/edit', data })
export const vendorList = (data) => http.post({ url: '/admin/vendor/list', data })
export const replyInfo = (data) => http.post({ url: '/admin/work-order/reply/page', data })
export const processingCase = (data) =>
  http.post({ url: '/admin/work-order/action/processing', data })
export const replyCase = (data) => http.post({ url: '/admin/work-order/reply/save', data })
export const solveCase = (data) => http.post({ url: '/admin/work-order/action/solve', data })
export const downloadCase = (data) =>
  http.post({ url: '/admin/work-order/report/download', data, responseType: 'blob' })
export const file2Url = (data) =>
  http.post({ url: '/admin/work-order/file/download-url/generator', data })

export const issueList = (data) => http.post({ url: '/admin/work-order/issue-buckets/page', data })
export const createIssue = (data) =>
  http.post({ url: '/admin/work-order/issue-buckets/save', data })
export const editIssue = (data) => http.post({ url: '/admin/work-order/issue-buckets/edit', data })
export const delIssue = (data) => http.post({ url: '/admin/work-order/issue-buckets/remove', data })
export const sortIssue = (data) =>
  http.post({ url: '/admin/work-order/issue-buckets/level/adjust', data })

// account module
export const accountList = (data) => http.post({ url: '/admin/account/page', data })
export const accountQueryList = (data) => http.post({ url: '/admin/account-all/list', data })
export const accountInfo = (data) => http.post({ url: '/admin/account/query', data })
export const adjustmentPost = (data) => http.post({ url: '/admin/account/adjust', data })
export const adjustmentVerificationCode = (data) =>
  http.post({ url: '/admin/account/adjust/verify-code/obtain', data })
export const depositPost = (data) => http.post({ url: '/admin/account/deposit/update', data })
export const extraPost = (data) => http.post({ url: '/admin/account/cost/extra/add', data })
export const businessTagList = (data) =>
  http.post({ url: '/admin/account/cost/all/business-tag/query', data })
export const unholdQuery = (accountId) =>
  http.post({ url: `/admin/transaction/holding-amount/query/${accountId}` })
export const unholdPost = (data) =>
  http.post({ url: '/admin/transaction/holding-order/all/cancel', data })

// merchant
export const merchantList = (data) => http.post({ url: '/admin/merchant/page', data })
export const merchantQueryList = (data) => http.post({ url: '/admin/merchant-all/list', data })
export const merchantUpdate = (data) => http.post({ url: '/admin/merchant/update', data })
export const merchantInfo = (data) => http.post({ url: '/admin/merchant/info', data })
export const merchantUnlock = (data) =>
  http.post({ url: '/admin/merchant/main-account/unlock', data })
export const merchantReset = (data) =>
  http.post({ url: '/admin/merchant/main-account/password/reset/link/sent', data })

// account
export const accountingUploadFiles = (data) =>
  http.post({ url: '/admin/approval/file/upload', data })
export const merchantAuthList = (data) =>
  http.post({ url: '/admin/approval/config/merchant/list', data })

export const createTopup = (data) => http.post({ url: '/admin/approval/topup/create', data })
export const topupList = (data) => http.post({ url: '/admin/approval/topup/page', data })
export const topupInfo = (data) => http.post({ url: '/admin/approval/topup/query', data })
export const accountingTopupConfig = (data) =>
  http.post({ url: '/admin/approval/topup/config/query', data })
export const accountingTopupFee = (data) =>
  http.post({ url: '/admin/approval/topup/fee/calculate', data })

export const createTransferOut = (data) =>
  http.post({ url: '/admin/approval/transfer-out/create', data })
export const transferOutList = (data) =>
  http.post({ url: '/admin/approval/transfer-out/page', data })
export const transferOutInfo = (data) =>
  http.post({ url: '/admin/approval/transfer-out/query', data })
export const accountingTransferOutConfig = (data) =>
  http.post({ url: '/admin/approval/transfer-out/config/query', data })
export const accountingTransferOutFee = (data) =>
  http.post({ url: '/admin/approval/transfer-out/fee/calculate', data })

export const createExchange = (data) => http.post({ url: '/admin/approval/exchange/create', data })
export const exchangeList = (data) => http.post({ url: '/admin/approval/exchange/page', data })
export const exchangeInfo = (data) => http.post({ url: '/admin/approval/exchange/query', data })
export const accountingExchangeConfig = (data) =>
  http.post({ url: '/admin/approval/exchange/config/query', data })
export const accountingExchangeFx = (data) =>
  http.post({ url: '/admin/approval/exchange/amount/calculate', data })

export const subMerchantList = (data) =>
  http.post({ url: '/admin/approval/sub-merchant/page', data })
export const createSubMerchant = (data) =>
  http.post({ url: '/admin/approval/sub-merchant/create', data })
export const subMerchantInfo = (data) =>
  http.post({ url: '/admin/approval/sub-merchant/query', data })
export const subMerchantRevoke = (data) =>
  http.post({ url: '/admin/approval/sub-merchant/cancel', data })

export const refundList = (data) => http.post({ url: '/admin/approval/refund/page', data })
export const refundInfo = (data) => http.post({ url: '/admin/approval/refund/query', data })

export const accountNodes = (data) => http.post({ url: '/admin/approval/node/list', data })
export const transactionDownloadFiles = downloadFiles('/admin/transaction/file/download')
export const transactionFile2Url = (data) =>
  http.post({ url: '/admin/transaction/file/download-url/generator', data })

// payment
export const payoutList = (data) => http.post({ url: '/admin/approval/payment-payout/page', data })
export const payoutInfo = (data) =>
  http.post({ url: '/admin/approval/payment-payout/detail', data })

// config
export const feeConfig = (data) => http.post({ url: '/admin/merchant/fee-config/query', data })

export const autoTransferCreate = (data) =>
  http.post({ url: '/admin/account/auto-transfer/config/save', data })
export const autoTransferList = (data) =>
  http.post({ url: '/admin/account/auto-transfer/config/page', data })
export const autoTransferSwitch = (data) =>
  http.post({ url: '/admin/account/auto-transfer/config/state/switch', data })
export const autoTransferInternalTransfer = (data) =>
  http.post({ url: '/admin/account/auto-transfer/config/internal-transfer', data })
export const autoTransferAccountInfo = (data) =>
  http.post({ url: '/admin/account/transfer-balance/query', data })

export const costParamsOption = (data) =>
  http.post({ url: '/admin/account/cost-config/init/query', data })
export const costConfigQuery = (data) =>
  http.post({ url: '/admin/account/cost-config/query', data })
export const costConfigList = (data) => http.post({ url: '/admin/account/cost-config/page', data })
export const costConfigDel = (data) => http.post({ url: '/admin/account/cost-config/delete', data })
export const costConfigAdd = (data) => http.post({ url: '/admin/account/cost-config/add', data })
export const costConfigUpdate = (data) =>
  http.post({ url: '/admin/account/cost-config/update', data })

// operation
export const transactionUploadFiles = (data) =>
  http.post({ url: '/admin/transaction/file/upload', data })
export const chargeBackUploadFiles = (data) =>
  http.post({ url: '/admin/charge-back-order/reply/appendix/upload', data })
export const chargeBackCasePage = (data) =>
  http.post({ url: '/admin/charge-back-order/page', data })
export const chargeBackCaseReplyList = (data) =>
  http.post({ url: '/admin/charge-back-order/reply/list', data })
export const chargeBackCaseInfo = (data) =>
  http.post({ url: '/admin/charge-back-order/query', data })
export const chargeBackCaseStatistics = (data) =>
  http.post({ url: '/admin/charge-back-order/status-statistics/query', data })
export const chargeBackCaseCreate = (data) =>
  http.post({ url: '/admin/charge-back-order/create', data })
export const chargeBackCaseReply = (data) =>
  http.post({ url: '/admin/charge-back-order/reply', data })
export const chargeBackCaseSolve = (data) =>
  http.post({ url: '/admin/charge-back-order/solve', data })

export const refundCasePage = (data) => http.post({ url: '/admin/refund-order/page', data })
export const refundCaseReplyList = (data) =>
  http.post({ url: '/admin/refund-order/reply/list', data })
export const refundCaseInfo = (data) => http.post({ url: '/admin/refund-order/query', data })
export const refundCaseAdditionaliInfo = (data) =>
  http.post({ url: '/admin/refund-order/additional-info/query', data })
export const refundCaseStatistics = (data) =>
  http.post({ url: '/admin/refund-order/status-statistics/query', data })
export const refundCaseCreate = (data) => http.post({ url: '/admin/refund-order/create', data })
export const refundCaseReply = (data) => http.post({ url: '/admin/refund-order/reply', data })
export const refundCaseSolve = (data) => http.post({ url: '/admin/refund-order/solve', data })
export const refundCaseUploadFiles = (data) =>
  http.post({ url: '/admin/refund-order/reply/appendix/upload', data })
export const transactionInfo = (data) => http.post({ url: '/admin/transaction/query', data })

export const productRevenueOptions = (data) =>
  http.post({ url: '/admin/transaction-cost/product/daily/init/query', data })
export const productRevenuDownload = (data) =>
  http.post({
    url: '/admin/transaction-cost/product/daily/report/download',
    data,
    responseType: 'blob'
  })

export const getVendorList = () => http.post({ url: '/admin/vendor/all/list' })

// kyc
export const kycList = (data) => http.post({ url: '/admin/kyc/dynamic-link/page', data })
export const kycOwnerList = (data) => http.post({ url: '/admin/kyc/metadata/owner/teams', data })
export const createKyc = (data) => http.post({ url: '/admin/kyc/dynamic-link/create', data })
export const getKycLink = (data) =>
  http.post({ url: '/admin/kyc/dynamic-link/token/acquire', data })

// For standalone and fixed file downloads, And the passed parameter contains ID
const maxAge = 1000 * 60 * 10
const cache = {}
const cacheCreateTime = {}
setInterval(() => {
  for (const key in cacheCreateTime) {
    if (Object.hasOwnProperty.call(cacheCreateTime, key)) {
      const time = cacheCreateTime[key]
      if (Date.now() - maxAge > time) {
        delete cache[key]
        delete cacheCreateTime[key]
      }
    }
  }
}, 1000 * 30)

function downloadFiles(url) {
  return (data) => {
    if (cache[data.id]) {
      return Promise.resolve(cache[data.id])
    }
    return http.post({ url, data, responseType: 'blob' }).then((res) => {
      cache[data.id] = res
      cacheCreateTime[data.id] = Date.now()
      return res
    })
  }
}

// Data Center
export const transactionSummary = (data) =>
  http.post({ url: '/admin/merchant/transaction-daily-summary/page', data })
export const transactionCount = (data) =>
  http.post({ url: '/admin/merchant/transaction-daily-summary/count', data })

export const transactionCostDailyList = (data) =>
  http.post({ url: '/admin/transaction-cost/daily/list', data })
export const transactionCostDailyDownload = (data) =>
  http.post({ url: '/admin/transaction-cost/daily/report/download', data, responseType: 'blob' })
export const transactionCostMonthlyList = (data) =>
  http.post({ url: '/admin/transaction-cost/monthly/list', data })
export const transactionCostMonthlyDownload = (data) =>
  http.post({ url: '/admin/transaction-cost/monthly/report/download', data, responseType: 'blob' })
export const transactionCostDailyGrossRevenueRate = (data) =>
  http.post({ url: '/admin/transaction-cost/gross-revenue-rate/daily/line-chart', data })
export const transactionCostDailyNetRevenueRate = (data) =>
  http.post({ url: '/admin/transaction-cost/net-revenue-rate/daily/line-chart', data })
export const transactionCostMonthlyGrossRevenueRate = (data) =>
  http.post({ url: '/admin/transaction-cost/gross-revenue-rate/monthly/line-chart', data })
export const transactionCostMonthlyNetRevenueRate = (data) =>
  http.post({ url: '/admin/transaction-cost/net-revenue-rate/monthly/line-chart', data })
export const transactionCostMerchantList = (data) =>
  http.post({ url: '/admin/transaction-cost/each-merchant/page', data })
export const transactionCostMerchantDownload = (data) =>
  http.post({
    url: '/admin/transaction-cost/each-merchant/report/download',
    data,
    responseType: 'blob'
  })

export const transactionHistory = (data) =>
  http.post({ url: '/admin/transaction/history-data/query', data })

// Message Center
export const getMessageList = (data) => http.post({ url: '/admin/message/list', data })
export const createMessage = (data) => http.post({ url: '/admin/message/create', data })
export const editMessage = (data) => http.post({ url: '/admin/message/edit', data })
export const getMessageDetail = (id) => http.post({ url: '/admin/message/detail', data: { id } })
export const getMerchantsList = () => http.post({ url: '/admin/message/merchant/query' })
export const deleteMessage = (messageIds) =>
  http.post({ url: '/admin/message/delete', data: { messageIds } })
