import { EN, BRL, MXN, USD, COP, CLP, PEN, ZAR, EUR } from '@/utils/constants'
import { EMPTY_TEXT } from '@/utils/constants'

let lang = (navigator.language || navigator.userLanguage || EN).substr(0, 2)

function resetToFixed() {
  if (Number.prototype.toFixed) {
    Number.prototype._toFixed = Number.prototype.toFixed
    Number.prototype.toFixed = function (num) {
      try {
        let text = 1.23
        text = text.toLocaleString(lang)
        // if the decimal point is ','
        return text.indexOf(',') > -1 ? this._toFixed(num).replace('.', ',') : this._toFixed(num)
      } catch (err) {
        console.log('toFixed', err)
      }
    }
  }
}

resetToFixed()

function isNumericString(variable) {
  return (typeof variable === 'number' || typeof variable === 'string') && !isNaN(variable)
}

export default {
  decimalConversionPercentage(value) {
    return isNumericString(value) ? (Number(value) * 100).toFixed(2) + '%' : value
  },

  centConversionYuan(value, fractionDigits = 2) {
    return isNumericString(value) ? (Number(value) / 100).toFixed(fractionDigits) : value
  },

  YuanConversionCent(value) {
    return isNumericString(value) ? Number((value * 100).toFixed(0)) : value
  },

  InfinityMap(value) {
    if (value >= 99999999999999999999999999) return ' ∞ '
    else return value
  },

  reportSize(size) {
    let temp = Number(size) / 1024
    size = size + 'B'
    if (temp >= 1) {
      // kb
      size = temp.toFixed(1) + 'KB'
      temp = temp / 1024
      if (temp > 1) {
        // mb
        size = temp.toFixed(1) + 'MB'
      }
    }
    return size
  },

  // Replace decimal point with comma
  specialCharactersThousandSegmentation(value) {
    let money = value
    if (money.indexOf(',') > -1) {
      money = money.replace(',', '.')
    }
    let result = ''
    let decimal = ''
    let integer = ''
    if (money < 1000) {
      result = money
    } else {
      if (money.indexOf('.') > 0) {
        let index = money.indexOf('.')
        decimal = money.slice(index, money.length)
        integer = money.slice(0, index)
      } else {
        integer = money
      }
      let num = parseInt(integer.length / 3)

      let head = integer.slice(0, integer.length - num * 3)
      if (head.length > 0) {
        head += '.'
      }
      let body = integer.slice(integer.length - num * 3, integer.length)

      let arr2 = []
      for (let i = 0; i < num; i++) {
        arr2.push(body.slice(i * 3, i * 3 + 3))
      }
      body = arr2.join('.')

      integer = head + body
      result = integer + decimal
    }

    result = result.substring(0, result.length - 3) + ',' + result.substring(result.length - 2)
    return result
  },

  thousandSegmentation(value) {
    return isNumericString(value)
      ? Number(String(value).replace(',', '.')).toLocaleString(lang, {
          minimumFractionDigits: 2
        })
      : value
  },

  currencyIdentifier(value) {
    const currencies = {
      BRL,
      MXN,
      USD,
      COP,
      CLP,
      PEN,
      ZAR,
      EUR
    }
    return currencies[value]
  },

  avatarName(value) {
    return value.substr(0, 1).toUpperCase()
  },

  makeAccountInfos(beneficiaryAccount) {
    var text = ''
    beneficiaryAccount?.accountInfos?.forEach((info) => {
      if (text.length > 0) {
        text += '\n'
      }
      if (info.value) {
        text += `${info.label}: ${info.value}`
      } else {
        text += `${info.label}`
      }
    })
    return text.length ? text : EMPTY_TEXT
  }
}
